import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';

import Router, { useRouter } from 'next/router';
import { Loading } from '@/components/shared';
import { useForm } from 'react-hook-form';
import { SignInwithAuth } from '@/lib/withAuth';

import { ButtonPrimary } from '@/components/atoms/button';
import { SigninLayout } from '@/components/atoms/layout';
import { useLogin } from '@/lib/hooks';
import { useAuthContext } from '@/context/AuthContext';

const DefaultPage = ({}) => {
  const router = useRouter();
  const { user } = useAuthContext();
  // react-fooks-form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (payload) => {
    // 送信するデータ
    const data = {
      login_id: payload.login_id,
      password: payload.password,
    };
    setIsLoading(true);
    const result = await useLogin(data);
    if (result.status === 200) {
      return null;
    } else if (result.status === 400) {
      alert(result.message);
    } else {
      alert(result.message);
      process.env.NODE_ENV === 'production' && router.push('/systemerror');
    }
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <Loading />}
      <SigninLayout title="ログイン">
        <div css="background-color: #fff;box-shadow: 1px 2px 3px 1px #cecece;">
          <section css="padding: 15px 10px;">
            <form onSubmit={handleSubmit(onSubmit)}>
              <h1 css="text-align:center;font-size: 24px;">理事会ログイン</h1>
              <LoginForm>
                <div>
                  <LoginFormLabel>ログインID</LoginFormLabel>
                  <LoginInput
                    type="text"
                    placeholder="ログインID"
                    {...register('login_id', { required: 'ログインIDを入力してください' })}
                  />
                  {errors.login_id && <p css="color: red;">{errors.login_id.message}</p>}
                </div>
                <div css="margin-top:40px;">
                  <LoginFormLabel>パスワード</LoginFormLabel>
                  <LoginInput
                    type="password"
                    placeholder="パスワード"
                    {...register('password', { required: 'パスワードを入力してください' })}
                  />
                  {errors.password && <p css="color: red;">{errors.password.message}</p>}
                </div>
              </LoginForm>
              <div css="text-align:center;margin-top:55px;">
                <ButtonPrimary styleType="regular" type="submit">
                  ログイン
                </ButtonPrimary>
              </div>
            </form>
          </section>
        </div>
      </SigninLayout>
    </React.Fragment>
  );
};

const Exp = styled.p`
  font-size: 0.8em;
  margin-top: 3px;
`;

const Box = styled.div`
  padding: 60px 15px 30px 15px;
  height: 550px;
`;

const LoginForm = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 40px;
  max-width: 500px;
  font-size: 16px;
`;

const LoginFormLabel = styled.p`
  font-weight: bold;
  margin-left: 5px;
  margin-bottom: 3px;
  font-size: 16px;
`;

const LoginInput = styled.input`
  width: 100%;
  height: 50px;
  border: 2px solid #e0e0e0;
  padding: 5px 10px;
  font-size: 1em;
  border-radius: 3px;
  max-width: 500px;
`;

export default SignInwithAuth(DefaultPage);
