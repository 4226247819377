import React from 'react';
import styled, { css } from 'styled-components';
import { Head, Footer, Header, Loading, Sidemenu } from '@/components/shared';

const ToppageLayout = ({ children, title, imgUrl }) => {
  return (
    <React.Fragment>
      <Head title={title} />
      <Header />
      <Main>
        <Img src={imgUrl} />
        <MainInner>
          {children}
          <Sidemenu />
        </MainInner>
      </Main>
      <Footer />
    </React.Fragment>
  );
};

const Main = styled.main`
  width: 100%;
  min-width: 1250px;
  margin: auto;
  flex: 1;
`;
const MainInner = styled.div`
  width: 90%;
  max-width: 1150px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
`;
const Img = styled.img`
  width: 100%;
  height: auto;
  object-fit: cover;
`;

export default ToppageLayout;
